import React, { useEffect, useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Post from "./components/Post";
import "./App.css";
import NewPost from "./components/NewPost";

function App() {
    const [posts, setPosts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    let getPosts = useCallback(async () => {
        const resp = await fetch("https://workers.andi6143.workers.dev/posts");
        const postsResp = await resp.json();
        setPosts(postsResp.reverse());
        setLoaded(true);
    }, []);

    useEffect(() => {
        getPosts();
    }, [getPosts]);

    let posts_html;
    if (loaded) {
        posts_html = posts
            .map((post) => (
                <Post
                    title={post.title}
                    username={post.username}
                    content={post.content}
                    key={`${post.title} by ${post.username}`}
                ></Post>
            ));
    } else {
        posts_html = (
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <Container>
            <h1 className="display-1">
                <span className="rainbow">hi there :0, welcome to</span>{" "}
                <strong className="blink">Fl4r3B00k</strong>
            </h1>
            <hr />
            <div className="posts-container">
                <h2>new post</h2>
                <NewPost getPosts={getPosts} />
                <hr />
                <h2>all posts</h2>
                {posts_html}
            </div>
        </Container>
    );
}

export default App;
