import Avatar from "boring-avatars";
import { useEffect, useState, useCallback } from "react";
import MDEditor from "@uiw/react-md-editor";

import "./NewPost.css";

function NewPost(props) {
    const [username, setUsername] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    let createPost = useCallback(async () => {
        const resp = await fetch("https://workers.andi6143.workers.dev/posts", {
            method: "POST",
            body: JSON.stringify({ title, username, content }),
        });
        await resp.json();
        setUsername("");
        setTitle("");
        setContent("");
        props.getPosts();
    }, [username, title, content, props]);

    return (
        <div className="new-post">
            <div className="post-header">
                <div style={{ marginRight: "1.5rem" }}>
                    <Avatar
                        size={64}
                        name={username}
                        variant="beam"
                        colors={[
                            "#92A1C6",
                            "#146A7C",
                            "#F0AB3D",
                            "#C271B4",
                            "#C20D90",
                        ]}
                    />
                </div>
                <div className="header-text">
                    <h4>
                        <input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="name me pls"
                        />
                    </h4>
                    <p>
                        by{" "}
                        <input
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="ur username"
                        />
                    </p>
                </div>
            </div>
            <MDEditor
                value={content}
                onChange={setContent}
                style={{ marginBottom: "0.5rem" }}
            />

            <button
                className="submit"
                type="button"
                onClick={createPost}
                disabled={!(title && username && content)}
            >
                post me
            </button>
        </div>
    );
}

export default NewPost;
