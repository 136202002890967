import React from "react";
import MDEditor from "@uiw/react-md-editor";
import Avatar from "boring-avatars";

function Post(props) {
    let marqueed = "";
    for (let i = 0; i < 100; i++) marqueed += `${props.title} // `;

    const rainbowStyle = {
        padding: "5px",
        border: "5px solid transparent",
        borderImage:
            "linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)",
        borderImageSlice: 1,
    };

    return (
        <div>
            <div style={rainbowStyle}>
                <div className="post-header">
                    <div style={{ marginRight: "1.5rem" }}>
                        <Avatar
                            size={64}
                            name={props.username}
                            variant="beam"
                            colors={[
                                "#92A1C6",
                                "#146A7C",
                                "#F0AB3D",
                                "#C271B4",
                                "#C20D90",
                            ]}
                        />
                    </div>
                    <div className="header-text">
                        <h4>
                            <marquee direction="right" behavior="alternate">
                                {marqueed}
                            </marquee>
                        </h4>
                        <p>
                            by <span className="blink">{props.username}</span>
                        </p>
                    </div>
                </div>
                <MDEditor.Markdown source={props.content} />
            </div>

            <br />
        </div>
    );
}

export default Post;
